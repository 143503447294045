import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";
import { baseURL } from "../config";

export const teachersubadminPath = "dasdas-teacher-admin";

export const adminPath = "mayar-adminn";
export const domainName = "mayar-hesham.com";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "indigo";
export const headerSectionColor = "indigo";
export const navbarIconColor = "text-indigo-500";
export const navbarDisclosureColor = "indigo";

export const progressBarColor = "purple";
export const loadingBarColor = "purple";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "rose";

export const autoChangeRandomNames = false;
export const autoChangeCenterWithNames = false;

export const isCenters = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "purple";

export const isAnalytics = false;

export const insertAutos = {
    "back-1": { link: `${baseURL}/insert_auto_card/back/1/`, name: " ضهر - جينيرال" },
    "front-1": {
        link: `${baseURL}/insert_auto_card/front/1/`,
        name: "كارت جينيرال - وش",
    },
    "back-2": { link: `${baseURL}/insert_auto_card/back/2/`, name: "كود كورس الدعامة -ضهر" },
    "front-2": {
        link: `${baseURL}/insert_auto_card/front/2/`,
        name: "كود كورس الدعامة - وش",
    },
    "front-3": {
        link: `${baseURL}/insert_auto_card/front/3/`,
        name: "كارت التعليمات",
    },
    //     "back-2": { link: `${baseURL}/insert_auto_card/back/2/`, name: "exam card" },
    //     "back-3": { link: `${baseURL}/insert_auto_card/back/3/`, name: "upstream card" },
    //     "back-4": {
    //         link: `${baseURL}/insert_auto_card/back/4/`,
    //         name: "كارت حصة واحدة مسبقة الدفع - ضهر",
    //     },
    //     "back-5": { link: `${baseURL}/insert_auto_card/back/5/`, name: "كارت حصتان مسبقة الدفع - ضهر" },
    //     "front-5": {
    //         link: `${baseURL}/insert_auto_card/front/5/`,
    //         name: "كارت حصتان مسبقة الدفع - وش",
    //     },
    //     "back-6": {
    //         link: `${baseURL}/insert_auto_card/back/6/`,
    //         name: "كارت سنه تالته ريڤيجين وان و تو ضهر",
    //     },
    //     "front-6": {
    //         link: `${baseURL}/insert_auto_card/front/6/`,
    //         name: "كارت سنه تالته ريڤيجين وان و تو وش",
    //     },
    //     "back-7": {
    //         link: `${baseURL}/insert_auto_card/back/7/`,
    //         name: "كارت سنه اولى ريڤيجين وان و تو ضهر",
    //     },
    //     "front-7": {
    //         link: `${baseURL}/insert_auto_card/front/7/`,
    //         name: "كارت سنه اولى ريڤيجين وان و تو وش",
    //     },
    //     "back-8": {
    //         link: `${baseURL}/insert_auto_card/back/8/`,
    //         name: "كارت سنه تانيه ريڤيجين وان و تو ضهر",
    //     },
    //     "front-8": {
    //         link: `${baseURL}/insert_auto_card/front/8/`,
    //         name: "كارت سنه تانيه ريڤيجين وان و تو وش",
    //     },
    //     "back-9": {
    //         link: `${baseURL}/insert_auto_card/back/9/`,
    //         name: "كارت ابستريم سنه اولى ضهر",
    //     },
    //     "front-9": {
    //         link: `${baseURL}/insert_auto_card/front/9/`,
    //         name: "كارت ابستريم سنه اولى وش",
    //     },
    //     "back-10": {
    //         link: `${baseURL}/insert_auto_card/back/10/`,
    //         name: "كارت ابستريم سنه تانية ضهر",
    //     },
    //     "front-10": {
    //         link: `${baseURL}/insert_auto_card/front/10/`,
    //         name: "كارت ابستريم سنه تانية وش",
    //     },
    //     "back-11": {
    //         link: `${baseURL}/insert_auto_card/back/11/`,
    //         name: "كارت ترافلر سنه اولى ضهر",
    //     },
    //     "front-11": {
    //         link: `${baseURL}/insert_auto_card/front/11/`,
    //         name: "كارت ترافلر سنه اولى وش",
    //     },
    //     "back-12": {
    //         link: `${baseURL}/insert_auto_card/back/12/`,
    //         name: "كارت ترافلر سنه تانية ضهر",
    //     },
    //     "front-12": {
    //         link: `${baseURL}/insert_auto_card/front/12/`,
    //         name: "كارت ترافلر سنه تانية وش",
    //     },
    //     "back-13": {
    //         link: `${baseURL}/insert_auto_card/back/13/`,
    //         name: "كارت حصه مسبقة الدفع سنه اولى ضهر",
    //     },
    //     "front-13": {
    //         link: `${baseURL}/insert_auto_card/front/13/`,
    //         name: "كارت حصه مسبقة الدفع سنه اولى وش",
    //     },
    //     "back-14": {
    //         link: `${baseURL}/insert_auto_card/back/14/`,
    //         name: "كارت حصه مسبقة الدفع سنه تانية ضهر",
    //     },
    //     "front-14": {
    //         link: `${baseURL}/insert_auto_card/front/14/`,
    //         name: "كارت حصه مسبقة الدفع سنه تانية وش",
    //     },
    //     "back-15": {
    //         link: `${baseURL}/insert_auto_card/back/15/`,
    //         name: "كارت حصة واحدة سنه اولى ضهر",
    //     },
    //     "front-15": {
    //         link: `${baseURL}/insert_auto_card/front/15/`,
    //         name: "كارت حصة واحدة سنه اولى وش",
    //     },
    //     "back-16": {
    //         link: `${baseURL}/insert_auto_card/back/16/`,
    //         name: "كارت حصة واحدة سنه تانية ضهر",
    //     },
    //     "front-16": {
    //         link: `${baseURL}/insert_auto_card/front/16/`,
    //         name: "كارت حصة واحدة سنه تانية وش",
    //     },
    //     "back-17": {
    //         link: `${baseURL}/insert_auto_card/back/17/`,
    //         name: "كارت حصتان سنه اولى ضهر",
    //     },
    //     "front-17": {
    //         link: `${baseURL}/insert_auto_card/front/17/`,
    //         name: "كارت حصتان سنه اولى وش",
    //     },
    //     "back-18": {
    //         link: `${baseURL}/insert_auto_card/back/18/`,
    //         name: "كارت حصتان سنه تانية ضهر",
    //     },
    //     "front-18": {
    //         link: `${baseURL}/insert_auto_card/front/18/`,
    //         name: "كارت حصتان سنه تانية وش",
    //     },
    //     "back-19": {
    //         link: `${baseURL}/insert_auto_card/back/19/`,
    //         name: "كارت يونيت ٥ سنه اولى ضهر",
    //     },
    //     "front-19": {
    //         link: `${baseURL}/insert_auto_card/front/19/`,
    //         name: "كارت يونيت ٥ سنه اولى وش",
    //     },
    //     "back-20": {
    //         link: `${baseURL}/insert_auto_card/back/20/`,
    //         name: "كارت يونيت ٦ بارت ١ سنه تانية ضهر",
    //     },
    //     "front-20": {
    //         link: `${baseURL}/insert_auto_card/front/20/`,
    //         name: "كارت يونيت ٦ بارت ١ سنه تانية وش",
    //     },
    //     "back-21": {
    //         link: `${baseURL}/insert_auto_card/back/21/`,
    //         name: "ليه كدة يا عصام ضهر",
    //     },
    //     "front-21": {
    //         link: `${baseURL}/insert_auto_card/front/21/`,
    //         name: "ليه كدة يا عصام وش",
    //     },
    //     "back-22": {
    //         link: `${baseURL}/insert_auto_card/back/22/`,
    //         name: "محافظة سوهاج ضهر",
    //     },
    //     "front-22": {
    //         link: `${baseURL}/insert_auto_card/front/22/`,
    //         name: "محافظة سوهاج وش",
    //     },
    //     "back-23": {
    //         link: `${baseURL}/insert_auto_card/back/23/`,
    //         name: "محافظة الأقصر ضهر",
    //     },
    //     "front-23": {
    //         link: `${baseURL}/insert_auto_card/front/23/`,
    //         name: "محافظة الأقصر وش",
    //     },
    //     "back-24": {
    //         link: `${baseURL}/insert_auto_card/back/24/`,
    //         name: "محافظة أسيوط ضهر",
    //     },
    //     "front-24": {
    //         link: `${baseURL}/insert_auto_card/front/24/`,
    //         name: "محافظة أسيوط وش",
    //     },
    //     "back-25": {
    //         link: `${baseURL}/insert_auto_card/back/25/`,
    //         name: "سنه تانية ابستريم ضهر",
    //     },
    //     "back-26": {
    //         link: `${baseURL}/insert_auto_card/back/26/`,
    //         name: "سنه تانية ترافلر ضهر",
    //     },
    //     "front-25": {
    //         link: `${baseURL}/insert_auto_card/front/25/`,
    //         name: "سنه تانية وش",
    //     },
    //     "back-27": {
    //         link: `${baseURL}/insert_auto_card/back/27/`,
    //         name: "سنه اولى ابستريم ضهر",
    //     },
    //     "back-28": {
    //         link: `${baseURL}/insert_auto_card/back/28/`,
    //         name: "سنه اولى ترافلر ضهر",
    //     },
    //     "front-27": {
    //         link: `${baseURL}/insert_auto_card/front/27/`,
    //         name: "سنه اولى وش",
    //     },
    //     "front-29": {
    //         link: `${baseURL}/insert_auto_card/front/29/`,
    //         name: "كارت الباشا تلميذ وش",
    //     },
    //     "back-29": {
    //         link: `${baseURL}/insert_auto_card/back/29/`,
    //         name: "كارت الباشا تلميذ : هنيدي - ضهر",
    //     },
    //     "back-30": {
    //         link: `${baseURL}/insert_auto_card/back/30/`,
    //         name: "كارت الباشا تلميذ : نجيبه - ضهر",
    //     },
    //     "back-31": {
    //         link: `${baseURL}/insert_auto_card/back/31/`,
    //         name: "كارت الباشا تلميذ : محمد سلام - ضهر",
    //     },
    //     "back-32": {
    //         link: `${baseURL}/insert_auto_card/back/32/`,
    //         name: "كارت الباشا تلميذ : طارق - ضهر",
    //     },
    //     "back-33": {
    //         link: `${baseURL}/insert_auto_card/back/33/`,
    //         name: "كارت الباشا تلميذ : إنجي - ضهر",
    //     },
    //     "front-34": {
    //         link: `${baseURL}/insert_auto_card/front/34/`,
    //         name: "مراجعه نهائية وش",
    //     },
    //     "back-34": {
    //         link: `${baseURL}/insert_auto_card/back/34/`,
    //         name: "مراجعه نهائية حصه تانية - ضهر",
    //     },
    //     "back-35": {
    //         link: `${baseURL}/insert_auto_card/back/35/`,
    //         name: "مراجعه نهائية حصه تالته - ضهر",
    //     },
    //     "back-36": {
    //         link: `${baseURL}/insert_auto_card/back/36/`,
    //         name: "مراجعه نهائية حصه رابع - ضهر",
    //     },
    //     "back-37": {
    //         link: `${baseURL}/insert_auto_card/back/37/`,
    //         name: "مراجعه نهائية حصه خامسه - ضهر",
    //     },
    //     "back-38": {
    //         link: `${baseURL}/insert_auto_card/back/38/`,
    //         name: "مراجعه نهائية حصه ساته - ضهر",
    //     },
    //     "front-39": {
    //         link: `${baseURL}/insert_auto_card/front/39/`,
    //         name: "مراجعه نهائية - حل - وش",
    //     },
    //     "back-39": {
    //         link: `${baseURL}/insert_auto_card/back/39/`,
    //         name: "مراجعه نهائية - حل - حصه اولى - ضهر",
    //     },
    //     "back-40": {
    //         link: `${baseURL}/insert_auto_card/back/40/`,
    //         name: "مراجعه نهائية - حل - حصه تانية - ضهر",
    //     },
    //     "back-41": {
    //         link: `${baseURL}/insert_auto_card/back/41/`,
    //         name: "مراجعه نهائية - حل - حصه تالته - ضهر",
    //     },
    //     "back-42": {
    //         link: `${baseURL}/insert_auto_card/back/42/`,
    //         name: "مراجعه نهائية - حل - حصه رابع - ضهر",
    //     },
    //     "back-43": {
    //         link: `${baseURL}/insert_auto_card/back/43/`,
    //         name: "مراجعه نهائية - حل - حصه خامسه - ضهر",
    //     },
    //     "back-44": {
    //         link: `${baseURL}/insert_auto_card/back/44/`,
    //         name: "مراجعه نهائية - حل - حصه ساته - ضهر",
    //     },
    //     "back-45": {
    //         link: `${baseURL}/insert_auto_card/back/45/`,
    //         name: "مراجعه نهائية - المراجعه بالكامل - ضهر",
    //     },
};

export const isAbleChangingPassword = false;

export const isMultiAdmin = false;

export const isSubjectSubscriptionable = false;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = false;
export const showVideoStatisticsTable = false;

export const isCategories = true;

export const isRegisterationMiddleName = false;
export const isRegisterationForthName = false;
export const isRegisterationFatherJob = false;
export const isRegisterationGender = false;

export const isCollapseCategories = false;

export const isWrongCode = false;

export const isUserStatisticsTable = false;

export const isRemotePlatformIntegrationEnabled = false;

export const isAdminToAdminNote = false;
export const isAdminAbleToChangeUsersYear = false;

export const isOtpEnabled = false;

export const isWalletEnabled = false;

export const isStudyPlannerEnabled = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isAdminUsers = false;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = true;
export const isInsertAutoTypes = true;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = false;
export const isCustomUserVideoLimit = false;

export const isCenterApi = false;

export const isLoginAsUser = false;

export const isEssayQuestions = false;

export const isAdminCourseStatistics = false;

export const isSearch = false;

export const isPinnedCourse = false;

export const isUnassignedSubscription = false;

export const isStoreLocatorAvailable = false;

export const isYoutubeVideoButton = false;

export const isCoursesAreRenamed = false;
